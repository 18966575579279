import React, { Component } from "react";
import Banner from "../../utils/banner/banner";
import BabaAhmedSquare from "../../../images/faculties/square.jpg";
import HRLine from "../../utils/hrline";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./hr.css";
// import { HrStaffData } from "./hr_data";
class HonourCausa extends Component {
  constructor(props) {
    super(props);

    this.state = {
      members: [],
    };
  }

  render() {
    return (
      <>
        <Banner
          image={require("./images/honoris_causa_banner.png")}
          title="Human Resources Office"
          caption=""
          disableTitle
        />

        <div className="container-fluid layout col1" style={{ margin: "20px" }}>
          <div className="row">
            <div className="layout-col col1 layout-12 col-sm-12">
              <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                <div className="row">
                  <div className="container text-justify">
                    <div className="row header">
                      <div className="col-sm-12">
                        <span>
                          <h3>About Honoris Causa</h3>
                          <p style={{ marginTop: "20px" }}>
                            The Honoris Causa distinction is a prestigious
                            recognition awarded to individuals who have made
                            outstanding contributions to society, academia, or
                            their respective fields. This honorary title
                            reflects excellence, leadership, and a commitment to
                            advancing knowledge and humanity. At Baze
                            University, we bestow this honor upon distinguished
                            individuals whose achievements embody our values of
                            innovation, service, and lifelong learning. Through
                            this recognition, we celebrate their impact and
                            inspire future generations to pursue greatness
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="layout-col col1 layout-12 col-sm-12">
              <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                <div className="row">
                  <div className="container text-justify">
                    <HRLine />
                    <div className="row header">
                      <div className="col-sm-12 text-justify">
                        <h3>Meet Our First Honoris Causa:</h3>
                      </div>
                    </div>
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div className="hr_card_flex_container">
                          <img
                            className="img-circle"
                            src={require("./avater.jpg")}
                            style={{ width: "80px" }}
                          />
                          <span style={{ marginLeft: "20px" }}>
                            {" "}
                            <h3>Dr. Ameyo Stella Adadevoh</h3>{" "}
                            <h5>Doctor of Letters</h5>
                          </span>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p style={{ color: "#8d8d8d", marginBottom: "15px" }}>
                          Dr. Amayo Stella Adadevoh (1956-2014) was a Nigerian
                          physician who heroically contained Nigeria's first
                          Ebola case, preventing a widespread outbreak. She paid
                          the ultimate price to release the patient, risking her
                          life for public safety. Her legacy of bravery and
                          sacrifice remains inspirational.
                        </p>
                        <div style={{ color: "#8d8d8d", fontSize: "0.9em" }}>
                          <p style={{ marginBottom: "5px" }}>
                            Awarded: October 2014
                          </p>
                          <p style={{ marginBottom: "5px" }}>
                            Convocation: First Convocation (Maiden)
                          </p>
                          <p style={{ marginBottom: "5px" }}>
                            Title: Doctor of Letters
                          </p>
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    <div className="row">
                      <HRLine />
                      {honorisCausaData.length > 0 &&
                        honorisCausaData.map((item, index) => {
                          return (
                            <div
                              className="col-md-6"
                              style={{ marginTop: "10px" }}
                            >
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel2a-content"
                                  id="panel2a-header"
                                >
                                  <div className="hr_card_flex_container">
                                    <img
                                      className="img-circle"
                                      src={require("./avater.jpg")}
                                      style={{ width: "80px" }}
                                    />
                                    <span style={{ marginLeft: "20px" }}>
                                      <h4>{item.name}</h4> <h5>{item.title}</h5>{" "}
                                    </span>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: item.body,
                                    }}
                                  />
                                  <div
                                    style={{
                                      color: "#8d8d8d",
                                      fontSize: "0.9em",
                                    }}
                                  >
                                    <p style={{ marginBottom: "5px" }}>
                                      Awarded: {item.award}
                                    </p>
                                    <p style={{ marginBottom: "5px" }}>
                                      Convocation: {item.convocation}
                                    </p>
                                    <p style={{ marginBottom: "5px" }}>
                                      Title: {item.title}
                                    </p>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HonourCausa;

const honorisCausaData = [
  {
    name: "Former Sultan of Sokoto Ibrahim Dasuki",
    body: `Ibrahim Dasuki (1923-2016), the 18th Sultan of Sokoto, ascended the throne in 1988 as the first Sultan from the Buhari line. A statesman and administrator, he shaped Nigeria's civil service and Islamic affairs. Though deposed in 1996, his legacy in governance and religious leadership remains significant.
`,
    award: "",
    convocation: "",
    title: "Doctor of Letters ",
  },
  {
    name: "Dr. Rose Ada Okwechime",
    body: `Dr. Rose Ada Okwechime, a distinguished banker, founded Abbey Mortgage Bank Pic, shaping Nigeria's housing finance sector. Her contributions earned her an Honorary Doctorate, reaffirming her influence. A leader in banking and service, she embodies excellence, innovation, and dedication, inspiring future generations in the financial industry.
`,
    award: "October 2021",
    convocation: "Seventh and Eighth Convocations",
    title: "Doctor of Science ",
  },
  {
    name: "Maj-Gen Mohammad Gado",
    body: `Lt. General (Rtd.) Muhammad Gado Nasko, born in 1941, served as Military Governor of Sokoto State (1978-1979) and held key ministerial roles. A seasoned artillery officer, he shaped Nigeria's military and governance. Retiring in 1993, he embraced farming and community leadership earning the Commander of the Order of the Niger (CON)`,
    award: "October 2021",
    convocation: "Seventh and Eighth Convocations",
    title: "Doctor of Science ",
  },
  {
    name: "Alhaji Shehu Umaru Ndanusa",
    body: `Alhaji Shehu Umaru Ndanusa (born 1946) is a renowned Nigerian businessman and former NACCIMA President. Leading enterprises in construction, banking, aviation, and insurance, he shaped Nigeria's industrial landscape. Chairman of Peugeot Nigeria, Orient Bank, and Skypower Airways, his legacy reflects resilience, innovation, and commitment to economic development.
`,
    award: "November 2022",
    convocation: "Ninth Convocations",
    title: "Doctor of Philosophy ",
  },
  {
    name: "Alhaji Bawa Garba",
    body: "Alhaji Bawa Garba (ABG) revolutionized Nigeria's media landscape by pioneering satellite broadcasting, leading to NBC's creation in 1992. Founder of Abacha Television, he shaped the industry's evolution. A visionary entrepreneur, his influence spans media, publishing, education, and commerce, leaving a lasting impact on Northern Nigeria's economy and broadcasting sector.",
    award: "",
    convocation: "Tenth Convocations",
    title: "Doctor of Letters ",
  },
  {
    name: "Late Hon. Justice Mohammed Bello, GCON",
    body: "Late Hon. Justice Mohammed Bello (1930-2004) served as Chief Justice of Nigeria (1987-1995), championing judicial independence. Born in Katsina, he blended Islamic and Western legal traditions. Educated at Barewa College and Lincoln's Inn, he rose through Nigeria's judiciary, shaping its legal landscape with integrity, expertise, and commitment to the rule of law.",
    award: "November 2023",
    convocation: "Tenth Convocations",
    title: "Doctor of Letters ",
  },
  {
    name: "Late Mrs Maryam Babangida",
    body: "Maryam Babangida (1948-2009) redefined Nigeria's First Lady role, using influence to empower women. Her 1987 Better Life Programme promoted economic independence for rural women. Despite controversies surrounding her husband's administration, her advocacy institutions, like the National Centre for Women's Development, cemented her legacy in Nigeria's social and political landscape.",
    award: "",
    convocation: "",
    title: "Doctor of Science ",
  },
  {
    name: "Late AVM Mouktar Mohammed",
    body: "Air Vice Marshal Moukhtar Mohammed was a distinguished Nigerian Air Force officer, statesman, and philanthropist. A key figure in the NAF's administration, he was known for strategic leadership and national service. His 2017 passing drew widespread respect, reflecting a lifetime dedicated to patriotism, governance, and Nigeria's development.",
    award: "9th November, 2024",
    convocation: "11th Convocation",
    title: "Doctor of Science ",
  },
  {
    name: "Dr. Oba Otudeko CFR",
    body: "Oba Otudeko CFR is a renowned Nigerian businessman, investor, and philanthropist. As founder of Honeywell Group, he shaped industries from finance to telecommunications. A former banker and policy influencer, he also championed education through the Oba Otudeko Foundation. His contributions earned national honours, reflecting his lasting impact on Nigeria's economy.",
    award: "",
    convocation: "",
    title: "Doctor of Philosophy ",
  },
];
