import React, { Component } from "react";
import Banner from "../../utils/banner/banner";
import BabaAhmedSquare from "../../../images/faculties/square.jpg";
import HRLine from "../../utils/hrline";
import ITRIDVC from "../itridvc";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { Link } from "react-router-dom";
import VCImage from './new_registrar.png'

class RegistrarOffice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            members: []
        }
    }
    render() {
        return <>
            <Banner image={require("../images/registrar_banner.png")} title="Office Of The Registrar" caption="" disableTitle />

            <div className="container-fluid layout col1" style={{ margin: "20px" }}>
                <div className="row">
                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <div className="row">
                                <div className="container text-justify">
                                    <div className="row header">
                                        <div className="col-sm-12">
                                            <span>
                                                <h3>Professor Abiodun Adeniyi</h3>
                                                <h5><strong>Registrar</strong></h5>
                                                <p style={{ marginTop: '20px' }} >
                                                    Professor Abiodun Adeniyi is a distinguished scholar and professor of communication whose versatile career spans academia, journalism, consultancy, and public engagement.
                                                    Renowned for his media, communication, and development expertise, Adeniyi has significantly contributed to knowledge dissemination, policy development, and societal progress.
                                                    Adeniyi commenced his academic journey at Ahmadu Bello University (ABU), Zaria, where he obtained his first degree.
                                                    His career in journalism took off at The Guardian Newspapers, Nigeria, where he excelled over a decade, covering diverse beats across Lagos and Abuja.
                                                    In 2003, he was awarded the prestigious British Chevening Scholarship, which led to his Master's degree in International Communications from the University of Leeds, England.
                                                </p>

                                                <p>
                                                    He further pursued a PhD at Leeds, focusing on "Internet and Diasporic Communication: Dispersed Nigerians and the Online Mediation of Distance, Longing, and Belonging."
                                                    His groundbreaking dissertation, one of the first African-centered studies published online through the joint Etheses Project with the Universities of Sheffield and York, examined how the internet shapes identity,
                                                    nationalism, and transnational experiences among the Nigerian Diaspora. During his doctoral studies, he also served as a Teaching Assistant at the University's Institute of Communication
                                                    Studies and earned his PhD in 2008.

                                                </p>
                                                <p>
                                                    Returning to Nigeria in 2009, Adeniyi became the Communications Consultant for the World Bank's Economic Reform and Governance Project (ERGP) at the Bureau of Public Procurement (BPP) in Abuja.
                                                    He led a multi-million-dollar media and communication initiative for four and a half years, embedding procurement reforms to promote transparency, competence, and efficiency in Nigeria's public spending.
                                                    Post-BPP, he took on the role of Lead Consultant at Witswords Consults Limited (WCL) in Abuja, advancing his influence in media consultancy and capacity building.                                                </p>
                                                <p>
                                                    In academia, Adeniyi joined Baze University as a Senior Lecturer in Mass Communication, later serving as Head of the Department from 2016 to 2023.
                                                    His contributions to diasporic and strategic development communication earned him a promotion to Associate Professor in 2018 and full professor in 2021.
                                                    He is the Dean of the School of Post-Graduate Studies, overseeing undergraduate, master's, and PhD programmes while mentoring future scholars.
                                                    He is also a visiting professor at Kogi State University in Kabba and the University of Abuja.
                                                </p>
                                                <p>
                                                    Adeniyi's academic influence extends to the American University of Nigeria (AUN), Yola, as a visiting professor in Communication and Multi-Media Design.
                                                    He is an external examiner for institutions like Pen Resource University (now North Western University) and the National Open University of Nigeria (NOUN).
                                                    His consultancy portfolio includes engagements with DFID, World Bank, EU, USAID, and the International Organization for Migration (IOM),
                                                    offering expertise in knowledge management and communication strategies for government bodies and high-net-worth individuals.
                                                </p>

                                                <p>
                                                    In 2023, his dedication to national development was acknowledged through his appointment as a member of the 17-professor committee
                                                    for establishing Kogi State University in Kabba and later as Deputy Chairman of the National Values Charter,
                                                    a Federal Government of Nigeria think tank focused on national reorientation.
                                                </p>

                                                <p>
                                                    Adeniyi is an accomplished author with over four dozen peer-reviewed articles and books.
                                                    His notable publications include "Diasporic Communication in the Digital Age" and "From Newsroom to Classroom:
                                                    Notions of Media Theory and Practice in Africa," with other upcoming titles.
                                                    A Fellow of the Chartered Institute of Communication and Development, he frequently contributes as a commentator, moderator, and motivational speaker.
                                                    Beyond his professional engagements, he enjoys tennis and swimming.
                                                </p>

                                            </span>
                                        </div>
                                    </div>

                                    <HRLine />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row">

                                                <div className="col-md-2">
                                                    <div className="media-left">
                                                        <span
                                                            style={{ fontSize: "60px" }}
                                                            className="icon icomoon ucws-business-and-finance-cockade-line"
                                                            aria-hidden="true" />
                                                    </div>

                                                </div>
                                                <div className="col-md-10">
                                                    <h3>Office of the Registrar</h3>
                                                    <p>
                                                        The Office of the registrar supports the Vice-Chancellor to ensure effective and efficient administration of the University.  The Registrar shall be Head of the Department of Administration (i.e registry) of the university and will be responsible to the Vice-Chancellor for the day-to-day administration of the university.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="media more-info-item">
                                                <div className="media-body">
                                                    <img src={VCImage} width="500px" height="500px" style={{ borderRadius: '7px' }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <div className="row">
                                <div className="container text-justify">
                                    <HRLine />

                                    <div className="row header">
                                        <div className="col-sm-12 text-justify">
                                            <h3>Our Responsibilities:</h3>
                                            <p>In line with the vision and Mission statement of the University the Office of the Registrar has the following specific responsibilities: </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="more-info-items two-items">
                                                <div className="media more-info-item">
                                                    <div className="media-body">
                                                        <p>
                                                            <ul style={{listStyle : "initial"}}>
                                                                <li>The Registrar shall be the custodian of the records, seal, other legal documents and traditions of the university. </li>
                                                                <li>The Registrar shall be the secretary to all statutory organs of the university, and will sit in advisory capacity at all committees of the board and senate as well as other administrative committees to guide on rules, regulations and university procedure.</li>
                                                                <li> The Registrar will be responsible for conveying information on the decisions of various organs of the institution to the relevant departments and units for effective implementation.</li>
                                                            </ul>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="media more-info-item">
                                                    <p>
                                                        <ul style={{listStyle : "initial"}}>

                                                            <li>
                                                                A Statute to be proved in any court shall bear or have affixed to it a certificate signed by the Registrar to the effect that a copy is a true copy of a statute of the university.
                                                            </li>
                                                            <li>
                                                                EThe Registrar shall be the custodian of the seal and other legal documents of the university except as regards matters for which the Bursar is responsible.
                                                            </li>
                                                            <li>
                                                                Ensure that organisational Units in the University remain focused on the vision, mission and strategic goals of the founder of the University.
                                                            </li>
                                                        </ul>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>;
    }
}

export default RegistrarOffice;
