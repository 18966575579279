import React, { Component } from "react";
import { Link } from "react-router-dom";

class Topbar extends Component {
    constructor() {
        super();

        this.state = {
            searchField: ""
        }
    }
    onSearchChange = (e) => {
        this.setState({ searchField: e.target.value });

    }

    onSubmitSearch = (e) => {
        e.preventDefault();

        if (this.state.searchField.trim().toString() === ""){
            return false;
        }
        window.location.href = `/search/${this.state.searchField}`;

    }

    render() {
        return <div id="toolbox" className="row red-back">
            <div id="redline">
                <div />
            </div>

            <div className="container">
                <div className="row button">
                    <div className="col-sm-12">
                        <div id="desktop-search" className="row">
                            <div className="col-sm-12">
                                <form id="search-desktop">
                                    <div className="btn-toolbar">
                                        <div className="btn-group search-field">
                                            <div className="input-group input-group-sm">
                                                <input id="uc-desktop-global-search-field"
                                                    type="text"
                                                    autoComplete="off"
                                                    value={this.state.searchField}
                                                    onChange={this.onSearchChange}
                                                    className="form-control" placeholder="Search Course, Staff, Publication, News and Event" />
                                                <span className="input-group-btn">
                                                    <button id="uc-desktop-global-search-submit" className="btn btn-search" onClick={this.onSubmitSearch}>
                                                        <span className="sr-only">Search</span>
                                                        <span aria-hidden="true">
                                                            <span className="ucws-icon ucws-icon-search" />
                                                        </span>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="btn-group">
                                            <button id="toolbox-toggle"
                                                className="btn btn-rounded btn-tab"
                                                type="button"
                                                data-ucws-analytics-category="Toolbox"
                                                data-ucws-analytics-label=""
                                                data-ucws-analytics-off-class="btn-tab">
                                                <span className="sr-only">Toggle Toolbox</span>
                                                <span aria-hidden="true">
                                                    <span className="ucws-icon ucws-icon-close" />
                                                    <span className="ucws-icon ucws-icon-chevron" />
                                                    <span className="ucws-icon ucws-icon-search" />
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row content">
                    <div className="col-sm-12">
                        <div id="mobile-search" className="row">
                            <div className="col-sm-12">
                                <form id="search-mobile" name="search-mobile" >
                                    <div className="btn-toolbar">
                                        <div className="btn-group search-field">
                                            <div className="input-group input-group-sm">
                                                <input id="uc-mobile-global-search-field" type="text"
                                                    value={this.state.searchField}
                                                    onChange={this.onSearchChange}
                                                    autoComplete="off"
                                                    className="form-control show-toolbox"
                                                    placeholder="Search Course, Staff, Publication"
                                                    tabIndex="-1" />
                                                <span className="input-group-btn">
                                                    <button id="uc-mobile-global-search-submit" className="btn btn-search" onClick={this.onSubmitSearch} >
                                                        <span className="sr-only">Search</span>
                                                        <span aria-hidden="true">
                                                            <span className="ucws-icon ucws-icon-search" />
                                                        </span>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div id="quicklinks" className="row">
                            <div className="col-sm-12 col-md-4 logins">
                                <ul>
                                    <li>
                                        <Link to="/about/about-baze">
                                            About Baze
                                        </Link>

                                    </li>
                                    <li>
                                        <a href="https://mail.google.com" target="_blank" rel="noopener noreferrer" >
                                            Web Mail
                                        </a>
                                    </li>
                                    {/*<li>*/}
                                    {/*    <a href="https://researchandinnovation.bazeuniversity.edu.ng/" target="_blank" rel="noopener noreferrer">Research</a>*/}
                                    {/*</li>*/}
                                    <li>
                                        <a href="https://portal.bazeuniversity.edu.ng/student/" target="_blank" rel="noopener noreferrer">Students</a>
                                    </li>
                                    <li>
                                        <a href="https://portal.bazeuniversity.edu.ng/staff/" target="_blank" rel="noopener noreferrer">Staff</a>
                                    </li>
                                    <li>
                                        <a href="https://portal.bazeuniversity.edu.ng/admission/" target="_blank" rel="noopener noreferrer">Admission</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-sm-12 col-md-8 links">
                                <ul>
                                    {/* <li>
                                        <Link to="/pro-chancellor-office">
                                            Prochancellor
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/vice-chancellor-office">
                                            Vice Chancellor
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/dvc-admin-office">
                                            DVC Admin
                                        </Link>
                                    </li> */}
                                    {/*<li>*/}
                                    {/*    <Link to="/itri-office">*/}
                                    {/*        IT, Res., & Inn.*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                    {/* <li>
                                        <Link to="/registrar-office">
                                            Registrar
                                        </Link>
                                    </li> */}
                                    <li>
                                        <Link to="/librarian-office">
                                            Library
                                        </Link>
                                        {/* <a href="https://library.bazeuniversity.edu.ng/" target="_blank" rel="noopener noreferrer">Library</a> */}
                                    </li>
                                    <li>
                                        <Link to="/resources/student-thesis">
                                            Student Thesis
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/academics/academic-calender">
                                            Academic Calender
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/resources/staff-publication">
                                            Staff Publication
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/academics/staff-list">
                                            Staff List
                                        </Link>
                                    </li>
                                    {/*<li>*/}
                                    {/*    <Link to='/vice-chancellor-office'>*/}
                                    {/*        Office of the VC*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                    {/*    <Link to="/dvc-admin-office">*/}
                                    {/*        Office of the DVC (Admin)*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}
                                    <li>
                                        <a href="https://portal.bazeuniversity.edu.ng/help_desk/" target="_blank" rel="noopener noreferrer">IT Help Services</a>
                                    </li>
                                    <li className="dropdown">
                                        <a className="dropdown-toggle" data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false" href="#">
                                            Faculty Websites <span className="ucws-icon ucws-icon-chevron" />
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-right">
                                            <li className="close-dropdown">
                                                <span className="close-icon">
                                                    <span className="sr-only">Close Faculty Websites List</span>
                                                    <span aria-hidden="true">
                                                        <span className="ucws-icon ucws-icon-chevron" />
                                                    </span>
                                                </span>
                                                <span className="notice">Viewing: <span>Faculty Websites</span></span>
                                            </li>
                                            {
                                                this.props.faculty_list.length > 0 ?
                                                    this.props.faculty_list.map((faculty, index) => {
                                                        return <li key={index}>
                                                            <Link to={`/faculty/${faculty.Slug}`}>
                                                                {faculty.FacultyName}
                                                            </Link>
                                                        </li>
                                                    })
                                                    : <li>
                                                        <Link to="/error">
                                                            Faculty Loading...
                                                        </Link>
                                                    </li>
                                            }

                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Topbar;
