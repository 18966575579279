import React, { useEffect } from "react";
import Banner from "../../utils/banner/banner";
import Square from "../../../images/faculties/square.jpg";
import axios from "axios";
import { serverLink } from '../../../resources/url';
import { useState } from "react";
import { titleCase } from "title-case";
import { currencyConverter } from '../../../resources/constants';

function TuitionFees() {
    const [facultyList, setFacultyList] = useState([]);
    const [feeList, setFeeList] = useState([]);

    const [facultyList2, setFacultyList2] = useState([]);
    const [feeList2, setFeeList2] = useState([]);

    const [facultyList3, setFacultyList3] = useState([]);
    const [feeList3, setFeeList3] = useState([]);

    const [formData, setformData] = useState({
        search: "",
        search_by: ""
    })

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        const { data } = await axios.get(`${serverLink}general/website/tuition-fee`);
        const facs_ = [...new Set(data.map((r) => { return r.FacultyName }))]
        await setFacultyList(facs_.length > 0 ? facs_.filter(x => x !== "No Faculty") : []);
        await setFeeList(data.length > 0 ? data.filter(x => x.FacultyName !== "No Faculty") : []);

        await setFacultyList2(facs_.length > 0 ? facs_.filter(x => x !== "No Faculty") : []);
        await setFeeList2(data.length > 0 ? data.filter(x => x.FacultyName !== "No Faculty") : []);

        await setFacultyList3(facs_.length > 0 ? facs_.filter(x => x !== "No Faculty") : []);
        await setFeeList3(data.length > 0 ? data.filter(x => x.FacultyName !== "No Faculty") : []);

        console.log(facs_)
    }

    const OnSearchByChange = (e) => {
        setFacultyList(facultyList3)
        setFeeList(feeList3);

        if (e.target.value !== "") {
            setformData({
                ...formData,
                search_by: e.target.value
            })
        } else {
            setFacultyList(facultyList3)
            setFeeList(feeList3)
        }
    }

    const onSearch = (e) => {
        const value = e.target.value;
        if (formData.search_by === "Course") {
            const fee_filter = feeList2.filter(x => x.CourseName.toLowerCase().includes(value));
            setFeeList(fee_filter)
        } else if (formData.search_by === "Faculty") {
            const fee_filter = facultyList2.filter(x => x.toLowerCase().includes(value));
            setFacultyList(fee_filter)
        } else {
            setFacultyList(facultyList3)
            setFeeList(feeList3)
        }
    }
    return <>
        <Banner image={Square} title="Tuition Fees" caption="" />
        <div className="container-fluid layout col1" style={{ margin: "20px" }}>
            <div className="row">
                <div className="layout-col layout-12 col-sm-12">
                    <div className="layout-blocks-ucws-text container-fluid roundable block text">
                        <div className="row">
                            <div className="container">
                                <div className="row left">
                                    <div className="col-sm-12 one-col block_quate">
                                        <h2>Tuition Fees</h2>
                                        <p>Baze delivers quality through experienced international staff, superb teaching equipment, overseas external examiners, and first-rate buildings to guarantee standards. Baze aims to provide university education to British standards in Nigeria at about half the cost of sending a student to study abroad</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="layout-col col1 layout-12 col-sm-12">
                    <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                        <div className="row">
                            <div className="container text-justify">
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <tbody>
                                            {
                                                feeList.length > 0 &&
                                                <tr>
                                                    <td colSpan="2" style={{ textAlign: 'right' }}>
                                                        <div className="d-flex justify-content-end">
                                                            <span style={{ marginRight: "20px", fontWeight: "bolder", fontSize: "20px" }}>Search</span>
                                                            <select id="filter_by" onChange={OnSearchByChange} style={{ width: "400px", height: "30px" }} placeholder="serach fee" className="form-control-sm"  >
                                                                <option value={""}>--All--</option>
                                                                <option value={"Course"}>Course</option>
                                                                <option value={"Faculty"}>Faculty</option>
                                                            </select>
                                                            <input onChange={onSearch} id="search" style={{ width: "400px", height: "30px" }} placeholder="serach keyword" className="form-control-sm" disabled={formData.search_by === ""} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                            <tr>
                                                <td colSpan="2" style={{ textAlign: 'center' }}><h2>Undergraduate</h2></td>
                                            </tr>
                                            {
                                                facultyList.length > 0 &&
                                                facultyList.filter(q => q !== "postgraduate school").map((x, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{titleCase(x)}</td>
                                                            <td>
                                                                {
                                                                    feeList.filter(j => j.FacultyName === x).map((r, k) => {
                                                                        return (
                                                                            <>
                                                                                <ul style={{ backgroundColor: "#ededed", padding: "10px" }}>
                                                                                    <li>
                                                                                        <span key={k} >{r.CourseName} <b>{currencyConverter(r.TuitionAmount)}</b></span>
                                                                                        <ul>
                                                                                            <li><span>{r.Level} Levels | {r.Semester} Semesters</span></li>
                                                                                        </ul>
                                                                                    </li>
                                                                                </ul>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                            <tr>
                                                <td colSpan="2" style={{ textAlign: 'center' }}><h2>Postgraduate</h2></td>
                                            </tr>
                                            {
                                                facultyList.length > 0 &&
                                                facultyList.filter(q => q === "postgraduate school").map((x, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{titleCase(x)}</td>
                                                            <td>
                                                                {
                                                                    feeList.filter(j => j.FacultyName === x).map((r, k) => {
                                                                        return (
                                                                            <>
                                                                                <ul style={{ backgroundColor: "#ededed", padding: "10px" }}>
                                                                                    <li>
                                                                                        <span key={k} >{r.CourseName} <b>{currencyConverter(r.TuitionAmount)}</b></span>
                                                                                        <ul>
                                                                                            <li><span>{r.Level} Levels | {r.Semester} Semesters</span></li>
                                                                                        </ul>
                                                                                    </li>
                                                                                </ul>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }


                                            <tr>
                                                <td>One-off Caution Deposit (Undergraduate)</td>
                                                <td>NGN 20,000.00</td>
                                            </tr>
                                            <tr>
                                                <td>One-off Admission Processing Fee (All)</td>
                                                <td>NGN 20,000.00</td>
                                            </tr>

                                            {/*<tr>*/}
                                            {/*    <td colSpan="2" style={{ textAlign: 'center' }}><h2>Others</h2></td>*/}
                                            {/*</tr>*/}

                                            {/*<tr>*/}
                                            {/*    <td>Hostel Fee(Boys)</td>*/}
                                            {/*    <td>*/}
                                            {/*        NGN 393,476.88 (Per Semester)<br />*/}
                                            {/*    </td>*/}
                                            {/*</tr>*/}
                                            {/*<tr>*/}
                                            {/*    <td>Hostel Fee(Girls)</td>*/}
                                            {/*    <td>*/}
                                            {/*        NGN 472,172.26 (Per Semester)<br />*/}
                                            {/*    </td>*/}
                                            {/*</tr>*/}
                                            {/*<tr>*/}
                                            {/*    <td>Hostel Fee (Single Room)</td>*/}
                                            {/*    <td>*/}
                                            {/*        NGN 786,953.75 (Per Semester)<br />*/}
                                            {/*    </td>*/}
                                            {/*</tr>*/}
                                            {/*<tr>*/}
                                            {/*    <td>Hostel MBBS</td>*/}
                                            {/*    <td>*/}
                                            {/*        NGN 590,215.30 (Per Semester)<br />*/}
                                            {/*    </td>*/}
                                            {/*</tr>*/}
                                            {/*<tr>*/}
                                            {/*    <td>Hostel MBBS - GIRLS</td>*/}
                                            {/*    <td>*/}
                                            {/*        NGN 708,258.38 (Per Semester)<br />*/}
                                            {/*    </td>*/}
                                            {/*</tr>*/}
                                            
                                            {/*<tr>*/}
                                            {/*    <td>Laundry (Optional)</td>*/}
                                            {/*    <td>*/}
                                            {/*        NGN 60,000.00 (Per Semester)<br />*/}
                                            {/*        NGN 90,000.00 (Per Semester) for MBBS Students  (200L and above)*/}
                                            {/*    </td>*/}
                                            {/*</tr>*/}


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="container text-justify">
                                <h3>Tuition Fee Payment Plan</h3>
                                <p>Kindly be notified that the payment plans introduced by the university (One Installment, Two Installments, Six Installments) are still very much available for use</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="container text-justify">
                                <h3>Improved Payment System</h3>
                                <p>Be reminded of the improved payment system by the university available at <a className="links" style={{ color: 'blue' }} href="payment.bazeuniversity.edu.ng" target={"_blank"} >payment.bazeuniversity.edu.ng</a>
                                    <br />
                                    Financial transactions are advised to be done using this platform in order to reduce registration wait time, queues at the Finance Office and ensure transaprency in financial transactions.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="container text-justify">
                                <h3>Tuition and Admission</h3>
                                <p>Getting an admission at the university does not guarantee a place unless the tuition is paid. Thus, for incoming or transfer students, paying the tuition is part of the acceptance process into the university. The tuition cost only covers the cost of courses undertaken for the semester.</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="container text-justify">
                                <h3>Tuition Revision</h3>
                                <p>The tuition cost is subject to revisions by the Senate of the university. This tuition change can be as a result of economic situations in the country or introduction of new systems that will lead to a more efficient learning environment. All students and parents/guardians will be informed of any new development regarding the tuition cost.</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="container text-justify">
                                <h3>Refund Policy on Tuition and Hostel Fees</h3>

                                <div className="table table-responsive-lg">
                                    <table className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>S/No</th>
                                                <th>Time of Refund Application</th>
                                                <th>Refund Applicable</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1. </td>
                                                <td>Before two weeks to commencement of the semester </td>
                                                <td>100% (less Admin Charges)</td>
                                            </tr>
                                            <tr>
                                                <td>2. </td>
                                                <td>Two weeks to 1st day of the semester </td>
                                                <td>50% (less Admin Charges)</td>
                                            </tr>
                                            <tr>
                                                <td>3. </td>
                                                <td>After 1st day of the semester</td>
                                                <td>0% (No Refund)</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                                <p>
                                    <strong>
                                        Kindly Note that :
                                        <ul style={{ listStyle: 'none' }}>
                                            <li>1. Refunds are computed from the date the Registrar or Burser is notified of through an Application for Refund.</li>
                                        </ul>
                                    </strong>
                                </p>
                            </div>
                        </div>



                        <div className="row">
                            <div className="container text-justify">
                                <h3>Scholarship</h3>
                                <p>The university currently has no scholarship opportunities for students.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default TuitionFees;
