import image_path from './img/rislan.png';
import noimage from './img/noimage.png';
import image_path2 from './img/david.png'
import image_path3 from './img/chand.png'
import image_path1 from './img/barnabas.png'

export const ResearchersData = [

    // {
    //     name: "Dr. Rislan Kanya",
    //     rank: "Senior Lecturer",
    //     faculty: "IT, Research and Innovation",
    //     email: "rislan.kanya@bazeuniversity.edu.ng",
    //     phone: "08035502270",
    //     image: image_path,
    //     profile: "https://bazeuniversity.edu.ng/academics/staff/abdulazeez-kanya-rislana"
    //
    // },

    // {
    //     name: "Aisha Guba",
    //     rank: "Senior Lecturer",
    //     faculty: "IT, Research and Innovation",
    //     email: "aisha.guba@bazeuniversity.edu.ng",
    //     phone: "09099604008",
    //     image: noimage,
    //     profile: "https://bazeuniversity.edu.ng/academics/staff/lawan-guba-aisha"
    // },

    {
        name: "Dr. David Etor",
        rank: "Senior Lecturer",
        faculty: "Engineering",
        email: "david.etor@bazeuniversity.edu.ng ",
        phone: "08024362335",
        image: image_path2,
        profile: ""

    },

    // {
    //     name: "Dr. Chandrashekhar Uppin",
    //     rank: "Senior Lecturer",
    //     faculty: "Computing and Applied Sciences",
    //     email: "cv.uppin@bazeuniversity.edu.ng ",
    //     phone: "08072662404",
    //     image: image_path3,
    //     profile: "https://bazeuniversity.edu.ng/academics/staff/chandrashekhar-uppin"
    // },

    {
        name: "Prof. Solomon Akhere Benjamin",
        rank: "Professor",
        faculty: "Management and Social Sciences",
        email: "solomonadekunle@bazeuniversity.edu.ng",
        phone: "08025471770",
        image: noimage,
        profile: "https://bazeuniversity.edu.ng/academics/staff/solomon-akhere--benjamin-"
    },
    {
        name: "Dr. Suleiman M. Barnabas",
        rank: "Senior Lecturer",
        faculty: "Management and Social Sciences",
        email: "barnabas.suleiman@bazeuniversity.edu.ng",
        phone: "08033263677",
        image: image_path1,
        profile: "https://bazeuniversity.edu.ng/academics/staff/barnabas--suleiman"
    },

    {
        name: "Mrs. Stella Peters",
        rank: "Research Fellow",
        faculty: "Law",
        email: "stella.peter@bazeuniversity.edu.ng",
        phone: "08063081680",
        image: noimage,
        profile: "https://bazeuniversity.edu.ng/academics/staff/stella-ekanem-peter"
    },

    {
        name: "Mrs. Martha Omem",
        rank: "Research Fellow",
        faculty: "Law",
        email: "martha.olorunda@bazeuniversity.edu.ng",
        phone: "07035949585",
        image: noimage,
        profile: "https://bazeuniversity.edu.ng/academics/staff/martha-biodun-omem"
    },

    {
        name: "Dr. AA. Muhammad Oumar",
        rank: "Senior Lecturer",
        faculty: "Environmental Science",
        email: "aa.oumar@bazeuniversity.edu.ng",
        phone: "08063081680",
        image: noimage,
        profile: "https://bazeuniversity.edu.ng/academics/staff/abdulrazzaq-ahmad-muhammad-oumar"
    },

    {
        name: "Dr. Muhammad Umbugala D",
        rank: "Senior Lecturer",
        faculty: "Environmental Science",
        email: "muhammad.douglas@bazeuniversity.edu.ng",
        phone: "08171260701",
        image: noimage,
        profile: "https://bazeuniversity.edu.ng/academics/staff/muhammad-douglas-umbugala"
    },
    {
        name: "Dr. Chimezie Nwosu",
        rank: "Senior Lecturer",
        faculty: "General Studies",
        email: "chimezie.nwosu@bazeuniversity.edu.ng",
        phone: "07032113612",
        image: noimage,
        profile: "https://bazeuniversity.edu.ng/academics/staff/nwosu--chimezie"
    },
    {
        name: "Mallam Sa'ad Abdulmumin",
        rank: "Senior Lecturer",
        faculty: "General Studies",
        email: "saad.abdulmumin@bazeuniversity.edu.ng",
        phone: "08068139820",
        image: noimage,
        profile: "https://bazeuniversity.edu.ng/academics/staff/saad--abdulmunin"
    },
    {
        name: "Maryam Sarki",
        rank: "Research Fellow",
        faculty: "Postgraduate School",
        email: "maryam.mohammed@bazeuniversity.edu.ng",
        phone: "083171365",
        image: noimage,
        profile: "https://bazeuniversity.edu.ng/academics/staff/maryam-muhammed-sarki"
    },
    {
        name: "Dr. Anthony Uwaechia",
        rank: "Senior Lecturer",
        faculty: "Engineering",
        email: "anthony.uwaechia@bazeuniversity.edu.ng",
        phone: "08035836464",
        image: noimage,
        profile: "https://bazeuniversity.edu.ng/academics/staff/anthony--ngozichukwuka-uwaechia"
    },
    {
        name: "Prof. Taofeek Uthman",
        rank: "Professor",
        faculty: "Medical Sciences",
        email: "taofeek.uthman@bazeuniversity.edu.ng",
        phone: "08035836464",
        image: noimage,
        profile: "https://bazeuniversity.edu.ng/academics/staff/mohammed-taofeek-olalekan-ibrahim"
    },
]


export const ResearchAreasData = [
    {
        dept: "ARCHITECTURE",
        researches: [
            {
                title: "Multimedia Technologies and the Future of Architecture in Nigeria (Lead Researcher: Suleiman Gimba):",
                brief: "This research aims to investigate the current state of multimedia technologies in architecture in Nigeria, and how they are being used by architects and designers and investigate the challenges associated with the adoption of multimedia technologies in architecture in Nigeria."
            }
        ],
        image: require("./img/arc.png"),
        fac: "ENV"
    },
    {
        dept: "BUSINESS MANAGEMENT AND MARKETING",
        researches: [
            {
                title: "Service Delivery Strategies for Private Universities in Nigeria (Lead Researcher: Jude E. Madu)",
                brief: "This research area aims to develop an operational and profitable Service Delivery Model for Private Universities"
            }
        ],
        image: require("./img/business.png"),
        fac: "BS"
    },
    {
        dept: "COMPUTER SCIENCE",
        researches: [
            {
                title: "Creation of African Human Iris Dataset for Personal Recognition, Age, Gender, Ethnicity and Disease Prediction (Lead Researcher: Oluwatobi Noah Akande)",
                brief: "This research area aims to capture human Iris datasets using Iris scanners, Preprocessing the captured iris datasets and make them publicly available for Iris related research in Africa."
            }
        ],
        image: require("./img/compt.png"),
        fac: "IT"
    },
    {
        dept: "GENERAL STUDIES",
        researches: [
            {
                title: "MEDIATION AND PEACEKEEPING: TOOLS FOR CONFLICT RESOLUTION AND PEACEFUL CO-EXISTENCE IN THE ECOWAS SUB-REGION (Lead Researcher: Chimezie Nwosu)",
                brief: "This research aims to assess and highlight the place and importance of mediation and peacekeeping in resolving intra-state conflict, particularly civil wars in the ECOWAS sub-region between 1989 and 2012"
            }
        ],
        image: require("./img/general.png"),
        fac: "GENS"
    },
    {
        dept: "LAW",
        researches: [
            {
                title: "The Role of Law in a growing entrepreneurial economy (Lead Researcher: Bilkisu Hassan)",
                brief: "This research aims to understand how legal frameworks can support or hinder entrepreneurship and identify ways legal systems can be improved to promote the growth of entrepreneurial activities."
            }
        ],
        image: require("./img/law.png"),
        fac: "LAW"
    },
    {
        dept: "LIBRARY",
        researches: [
            {
                title: "Trends in Oil and Gas Research in Nigeria: A Bibliometric Analysis using Google Scholar database (Lead Researcher: Amina Badaru)",
                brief: "The research study aims to determine the research trend in oil and gas research in Nigeria on the Google Scholar database."
            }
        ],
        image: require("./img/general.png"),
        fac: "GENS"
    },
    {
        dept: "MEDICAL LABORATORY SCIENCE",
        researches: [
            {
                title: "Pharmaceutical drugs vs Healing foods  (Lead Researcher: Ewaoche Itodo)",
                brief: "This research area focuses on providing an alternative treatment for diseases currently being treated with allopathic drugs."
            }
        ],
        image: require("./img/medical.png"),
        fac: "MED"
    },
    {
        dept: "PSYCHOLOGY",
        researches: [
            {
                title: "Influence of cashless policy on Students' mental health  (Lead Researcher: Obisesan Oluwunmi)",
                brief: "This research area aims to investigate the predictive effect of coping strategies on mental well-being among Baze University Students during the cashless policy in Nigeria."
            }
        ],
        image: require("./img/psy.png"),
        fac: "GENS"
    },
    {
        dept: "RADIOGRAPHY AND RADIATION SCIENCE",
        researches: [
            {
                title: "Impact of size-specific dose estimates based on water equivalent diameter in the establishment of diagnostic reference levels for pediatric CT examinations in a resource-constraints setting in Northern Nigeria (Lead Researcher: Mohammed Sani Umar)",
                brief: "This research focuses on the Impact of size-specific dose estimates based on water equivalent diameter in the establishment of diagnostic reference levels for pediatric CT examinations."
            }
        ],
        image: require("./img/radio.png"),
        fac: "MED"
    },


]