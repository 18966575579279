import React, { Component } from "react";
import "./banner.css";

const Banner = (props) => {
  return (
    <div
      className="layout-blocks-ucws-sidekick-cta container-fluid block  sidekick-cta full-width full-bleed inline-bg-image bg-image"
      style={{ margin: "0px" }}
    >
      <div className="row red-back hleft">
        <div className="" style={{ height: "400px" }}>
          <picture style={{ display: "block", width: "100%", height: "100%" }}>
            <img
              src={props.image}
              alt="Baze University Abuja"
              style={{
                width: "100%",
                height: "400px",
                objectFit: "cover",
                display: "block",
              }}
            />
          </picture>
        </div>
        {!props.disableTitle && (
          <div className="col-text">
            <div className="cta-wrapper">
              <div className="cta-content">
                <h2 className="left">{props.title}</h2>
                {props.caption !== "" ? (
                  <p className="text-justify">{props.caption}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Banner;
