import React, { Component } from "react";
import Banner from "../../utils/banner/banner";
import BabaAhmedSquare from "../../../images/faculties/square.jpg";
import HRLine from "../../utils/hrline";
import ITRIDVC from "../itridvc";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { Link } from "react-router-dom";
import VCImage from './dvc_academics.png'

class DeputyViceChancellorAcademics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            members: []
        }
    }

    render() {
        return <>
            <Banner image={require("../images/DVC academics.jpg")} title="Office Of The Deputy Vice Chancellor" caption="" disableTitle />

            <div className="container-fluid layout col1" style={{ margin: "20px" }}>
                <div className="row">
                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <div className="row">
                                <div className="container text-justify">
                                    <div className="row header">
                                        <div className="col-sm-12">
                                            <span>
                                                <h3>Professor Osita Agbu</h3>
                                                <h5><strong>Deputy Vice-Chancellor, Academics</strong></h5>
                                                <p style={{ marginTop: '20px' }} >
                                                    OSITA AGBU (Ph.D) is Professor of International Relations at the Department of International Relations and Diplomacy, Baze University, Abuja.
                                                    He is at present the Deputy Vice-Chancellor (Academic) of the university. He obtained his first degree in Political Science from the University of Ibadan in 1985,
                                                    and his Masters and Doctorate degrees in International Relations from the University of Nigeria in 1988 and 1993 respectively.
                                                </p>

                                                <p>
                                                    He has specializations in Governance and Democratization, Post-Conflict Peacebuilding, Africa-China Relations, Technology and Development,
                                                    and Nigeria’s Foreign Policy. He was formerly, Dean, Faculty of Management and Social Sciences, Baze University; and Head,
                                                    Division of International Politics at the Nigerian Institute of International Affairs (NIIA), Lagos where he worked for over two decades.
                                                    While at the NIIA, he was editor of the Nigerian Journal of International Affairs (NJIA)and the Nigerian Forum for several years.
                                                </p>

                                                <p>
                                                    He is currently the Editor-in-Chief of the Baze Journal of Management and Social Sciences.
                                                    Professor Agbu has to his credit over 100 publications that include several single-authored, edited and co-edited books; chapter contributions and Journal articles on various areas of Political Science and International Relations.
                                                    He is co-author, Cameroon-Nigeria Relations: Trends and Perspectives, U.S, Lexington Books (2022). Co-editor, Nigeria in the Global Arena, Past, Present and Future, Lagos, FOG Ventures, 2011. Editor,
                                                    The Politics and Selection of UN Secretary General (NIIA, 2018); and co-editor, Gas Flaring in the Niger Delta: The Internal and External Dimensions (NIIA:2018) amongst others.
                                                    He has lectured at The University of Nigeria, Nsukka; Caleb University, Lagos; and the Post-Graduate School of the University of Lagos.
                                                    He has also consulted for several regional and international organizations.
                                                </p>

                                                <p>
                                                    Professor Agbu was a laureate of the Governance Institute of the Council for the Development of Social Science Research in Africa (CODESRIA) in Dakar,
                                                    Senegal in 1996; a Visiting Research Fellow at the Nordic Africa Institute, Uppsala, Sweden n 2003; Visiting Fellow of the Institute for Global Dialogue,
                                                    Johannesburg, South Africa in 2001; and Senior Research Fellow of the Institute for Developing Economies, Chiba, Japan in 2006. He is married with children.
                                                    And is fulfilled contributing to the production of knowledge and education of young Nigerians. He likes keeping fit, listening and singing to good Reggae music.
                                                </p>

                                            </span>
                                        </div>
                                    </div>

                                    <HRLine />

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="media-body">
                                                <img src={VCImage} width="500px" style={{ borderRadius: '7px' }} />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <span
                                                        style={{ fontSize: "60px" }}
                                                        className="icon icomoon ucws-business-and-finance-cockade-line"
                                                        aria-hidden="true" />
                                                </div>
                                                <div className="col-md-10">
                                                    <div className="media media-body">
                                                        <h3>Office of the DVC Academics </h3>
                                                        <p>
                                                            The DVC Academics supports the Vice-Chancellor in ensuring the effective and efficient management of academic activities within the university.
                                                            This includes providing strategic leadership in academic policy formulation, curriculum development, and quality assurance.
                                                            The office also oversees student affairs, faculty development, research support, and the accreditation of academic programs in collaboration
                                                            with the National Universities Commission and other regulatory bodies. By fostering a conducive environment for teaching and learning,
                                                            the DVC Academics plays a key role in achieving the university’s academic mission and strategic goals.
                                                        </p>
                                                        <h3>Units</h3>
                                                        <p>The units under the jurisdiction of the Deputy Vice Chancellor Academics includes: </p>
                                                        <ul style={{ listStyle: "initial" }}>
                                                            <li>Academic Planning Directorate</li>
                                                            <li>Student Affairs</li>
                                                            <li>Examinations and Record Office</li>
                                                            <li>University's Library</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <div className="row">
                                <div className="container text-justify">
                                    <HRLine />

                                    <div className="row header">
                                        <div className="col-sm-12 text-justify">
                                            <h3>Our Responsibilities:</h3>
                                            <p>In line with the vision and mission statement of the University the Office of the DVC (Academics) has the following specific responsibilities: </p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="more-info-items two-items">
                                                <div className="media more-info-item">
                                                    <div className="media-body">
                                                        <p>
                                                            <ul style={{ listStyle: "initial" }}>
                                                                <li>Develop and implement academic policies and strategies.</li>
                                                                <li>Oversee the curriculum development and review process.</li>
                                                                <li>Ensure compliance with accreditation standards and regulations.</li>
                                                                <li>Foster a supportive environment for teaching and research excellence.</li>

                                                            </ul>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="media more-info-item">
                                                    <p>
                                                        <ul style={{ listStyle: "initial" }}>

                                                            <li>Ensure adherence to ethical and professional standards.</li>
                                                            <li>Support the development of academic facilities and infrastructure.</li>
                                                            <li>Promote continuous improvement in teaching and learning practices.</li>
                                                            <li>Advise the Vice-Chancellor and Senate on academic matters.</li>
                                                        </ul>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>;
    }
}

export default DeputyViceChancellorAcademics;
