import React, { Component } from "react";
import Banner from "../../utils/banner/banner";
import HRLine from "../../utils/hrline";
import VCImage from '../../../images/Vice_chancellor_baze.png'
import { isMobile } from "react-device-detect";

class ViceChancelloroffice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            members: []
        }
    }


    render() {
        return <>
            <Banner image={require("../images/vc_banner.png")} title="Office Of The Vice Chancellor" caption="" disableTitle />

            <div className="container-fluid layout col1" style={{ margin: "20px" }}>
                <div className="row">
                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <div className="row">
                                <div className="container text-justify">
                                    <div className="row header">
                                        <div className="col-sm-12">
                                            <span>
                                                <h3>Prof. Jamila Shu’ara</h3>
                                                <h5><strong>Vice-Chancellor</strong></h5>
                                                <p style={{ marginTop: '20px' }} >
                                                    Prof. Jamila Shu’ara is an alumnus of Howard University Washington DC, USA and Bayero University Kano, Nigeria and holds BSc., MSc.
                                                    and PhD Certificates in Consumer Sciences. At Howard University, she was recognized severally on the Dean's List and the Dean's Honours Roll and was awarded a tuition-free scholarship for her studies.
                                                    She is a Teacher by Profession, a Fellow of the Nigerian Institute of Management, a Fellow of the Forum of Women Educationalists and a Fellow of the Home Economics Professionals Association of Nigeria (HEPAN).
                                                    She commenced public service in 1981 as a Lecturer in the Nigerian Colleges of Education system and rose through the academic ranks to the terminal post of Chief Lecturer.
                                                    She also held administrative positions as Head of Department, Dean, Deputy Provost, and Provost at the Federal College of Education.
                                                    She participated as a Resource person on several NCCE Accreditation Teams to many Colleges of Education.  She has several Books, Journal Articles, and Technical Reports to her credit.
                                                     <br />
                                                </p>
                                                <p>
                                                   In 1999, she transferred her service to the Federal Civil Service Commission on the Education Officers cadre and was posted to the Federal Ministry of Education (FME), Headquarters Abuja.
                                                    She had a rich career in the federal bureaucracy and was deployed in various capacities, notable of these were:
                                                    <ol>
                                                    <li>Pioneer Director at the Teachers Registration Council in charge of Administration & Finance where she was directly in charge of the take-off of the organization and worked with colleagues to develop the Template for the recognition and registration of qualified Teachers in Nigeria;</li>
                                                    <li>National Coordinator of the FME National Project Coordinating Unit to manage the FME/World Bank/ Universal Basic Education Project (UBEP) II which had a $100 million draw-down components in basic education institutions for capacity building and infrastructure development in eleven states and FME. The successful completion of this intervention birthed STEP-B;</li>
                                                    <li>Director of the Department of Tertiary Education at the Federal Ministry of Education for over six years and her job schedule was high-level policy formulation and the management of the entire tertiary education sector including all Nigerian Universities, Polytechnics, Monotechnics, Innovative Enterprise Institutions, Colleges of Education, vibrant labour unions, supervision of Regulatory Agencies as the Tertiary Education Trust Fund, the National Universities Commission, the National Board for Technical Education, the National Commission for Colleges of Education, the National Teachers Institute, the Joint Admissions and Matriculation Board, the National Mathematical Centre, among others.</li>
                                            </ol>
                                                <br />
                                                </p>
                                                <p>
                                                    In 2012, Prof Jamila, having passed a set of rigorous written and oral examinations, was appointed a Federal Permanent Secretary;
                                                    the first Education Officer in the history of the Federal Ministry of Education to have achieved this feat.
                                                    She was subsequently deployed to six highly challenging and sensitive areas of governance including the Office of Secretary to the Government of the Federation (OSGF);
                                                    the Office of the Head of the Civil Service of the Federation (OHCSF), the Ministry of Aviation; Ministry of Petroleum Resources;
                                                    Ministry of Special Duties & Intergovernmental Affairs; and the Federal Ministry of Education. In all these deployments, she proved her mettle.
                                                        <ol>
                                                        <li>At the Office of Secretary to the Government of the Federation (OSGF), her dogged leadership enabled the Special Duties Office to produce a Compendium of all the Recipients of the National Honours Award from its inception in 1963 to 2012; the absence of good documentation had culminated in needless embarrassment from double nominations</li>
                                                        <li>At the Office of the Head of the Civil Service of the Federation (OHCSF), her leadership at the Career Management Office improved the conduct and management of promotion examinations for civil servants. She also developed parameters to upgrade the academic status of Federal Training Centers from mere in-service arrangements to accredited Monotechnics that award academically recognized Certificates and equivalents</li>
                                                        <li>At the Federal Ministry of Aviation, she galvanized the Parastatals (FAAN, NCAA, NIMET, NAMA, NCAT, AIB) into the Team that ensured the re-certification of Nigeria's Airport facilities as Category One (CAT.I) by the US National Transport and Safety Board (NTSB), this was particularly important for Nigeria's Open Skies efforts in tourism and business. Under her guidance, the Ministry continued the Airport re-modelling projects; escalated the need to quickly address the repair of the failed sections of the only Runway of Abuja Airport and the urgent need to build the 2nd Runway; proposed and implemented capacity sustainability and succession plans for Aviation Bureaucrats, etc.</li>
                                                        <li>At the Ministry of Special Duties and Intergovernmental Affairs, she harnessed the competencies, capacities, and expertise of staff to develop assessment instruments to monitor and evaluate the federal government's programmes and projects to determine impact</li>
                                                        <li>At the Ministry of Petroleum Resources, she brought a wealth of experience to reduce shutdowns and restiveness of the vibrant trade unions, she instituted real-time tracking devices for the movement of petroleum products; put in place effective sanctions where the petroleum products distribution channels were deliberately thwarted; implemented rich policies on institutional memory, respect for due process; environmental sustainability, reduction in pollution and gas flare, robust community engagement; tackled debt and budget deficits with oil majors; and the produced the initial draft of the Petroleum Industry Governance Bill (PIGB), etc;</li>
                                                        <li>At the Federal Ministry of Education, she advocated the responsible use of Bilateral Education Agreements (BEA) Scholarships, sustained fiscal commitments to international development partners advocated quality assurance for educational institutions, and promoted transparency in public funds, etc.</li>
                                                        </ol>
                                                    <br />
                                                </p>
                                                <p>
                                                    Prof. Jamila has a very robust experience in public institution governance and high-level private sector management; at various times,
                                                    she was a member of the Governing Council of many Federal Universities, Polytechnics,
                                                    and Colleges. She was a member of the TETFund and the National Universities Commission Board of Trustees.
                                                    She has served either as Chairperson or Member on more than fifty (50) Federal Government Committees on various domestic issues;
                                                    education, health, public sector financing, homeland security, etc.
                                                    She also served on multi-lateral Boards;
                                                    notably, the Board of Directors, of Bonny Gas Transport Ltd (BGT); the Nigeria Liquefied Natural Gas Ltd (NLNG) Bonny Island;
                                                    was Nigeria's Governor to the Organization of Petroleum Exporting Countries (OPEC) in Vienna,
                                                    the Head of Delegation of Nigeria to the 167th OPEC Conference, Vienna and the President, Committee of Experts, African Petroleum Producers Association (APPA).<br />
                                                </p>
                                                <br/>
                                                <p>
                                                   In 2016, after attaining the retirement age of sixty years, and in recognition of meritorious and exemplary service,
                                                   the Federal Government of Nigeria offered her a one-year extension of service, which she served out in early 2017.
                                                   This was further extended, but she declined and left the public service thereafter.
                                                </p>
                                                <br/>

                                                <p>
                                                    As a young officer in 1993, she initiated, formed, and nurtured the National Association of Women in Colleges of Education (WICE) to assist women fill career capacity gaps in a sector that greatly needed a hammer to shatter the glass ceiling.
                                                    Today, women in more than one hundred COEs have a strong network under WICE, with over fifty thousand active members from every geographical zone of Nigeria.
                                                    Furthermore, arising from the several affirmative actions on women's career development, which she championed.
                                                    Today, many highly qualified professional women are in top academic and management positions in the COE sector.
                                                    In recognition of her contributions to that sector, she was conferred with a Life Member of WICE.
                                                </p>
                                                <br/>
                                                <p>
                                                    At the Federal College of Education (Technical) Bichi, Kano State where she worked for eleven years,
                                                    a building was named after her in recognition of her numerous services to the institution.
                                                    At the Federal Polytechnic Offa, Jamila Day was instituted in recognition of her peaceful intervention in crisis management.
                                                    She is a founding member and now Chairperson of the Board of Trustees of the Forum of African Women Educationalists (FAWE) Nigeria - a group recognized and funded by the African Union for women's advocacy and improved quality of life through education.
                                                    She is also the Chairperson of the Board of Trustees of the Inspire1Network - a non-governmental organization that inspires girls and women for a better life.
                                                </p>
                                                <br/>


                                                <p>
                                                    Prof. Jamila Shu’ara joined Baze University in 2017 as a Senior Lecturer and Director of Strategy & Special Duties,
                                                    she was later appointed as the Director of Academic Planning and served in this capacity for more than two years before a recent appointment as REGISTRAR.
                                                    During her stay at Baze University, her schedule includes teaching; academic planning, resource verification, accreditation of programmes;
                                                    brand visibility; media and social media publicity; Chief Administrative Officer, etc. She brings robust expertise in public administration,
                                                    bureaucracy, governance and management of Tertiary Education Institutions (TEIs). She is a Professor of Consumer Sciences in the Department of Marketing.
                                                    She has authored over eighty academic publications including Journal Articles and Textbooks and is an International Peer Reviewer for some high-impact Journals.
                                                </p>
                                                <br/>

                                                <p>
                                                    At Baze University Abuja, she continues to mentor and offer invaluable service.
                                                    The Board of Trustees recently appointed her as the first Female Vice Chancellor of the university and named the magnificent Faculty of Law Annex after her,
                                                    in recognition of her meritorious contributions to the university.
                                                    She is a quintessential personality, a goal-getter who gets the job done with minimal disruptions, she continues to inspire multitudes and is never afraid of reforms.
                                                </p>

                                            </span>
                                        </div>
                                    </div>

                                    <HRLine />

                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="table-responsive">
                                                <img src={VCImage} width={isMobile ? "40%" : "100%"} style={{ borderRadius: '7px', }} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <span
                                                        style={{ fontSize: "60px" }}
                                                        className="icon icomoon ucws-business-and-finance-cockade-line"
                                                        aria-hidden="true" />
                                                </div>
                                                <div className="col-md-10">
                                                    <div className="media media-body">
                                                        <h3>Office of the Vice Chancellor</h3>
                                                        <p>
                                                            The Office of the Vice-Chancellor occupies the peak of the pyramid in the hierarchical administrative and academic structure of the University. The Office is organized in a way that it supervises all the functional areas of the institution in academic and non- academic spheres of activities towards achieving the vision and goals of the university.
                                                        </p>
                                                        <p>To assist him discharge these responsibilities and achieve institutional goals, the office of the Vice Chancellor has designed some action points and procedures as follows </p>
                                                        <ul style={{ listStyle: "initial" }}>
                                                            <li>Online tracking of Memos and other correspondences attended to by the Vice Chancellor</li>
                                                            <li>An organized filing system to safeguard documents and ease location of documents</li>
                                                            <li>People friendly approach to all visitors coming to the office for business</li>
                                                            <li>Appointments bookings and scheduling</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="layout-col col1 layout-12 col-sm-12">
                        <div className="layout-blocks-ucws-more-info container-fluid roundable block more-info">
                            <div className="row">
                                <div className="container text-justify">
                                    <HRLine />

                                    <div className="row header">
                                        <div className="col-sm-12 text-justify">
                                            <h3>Our Responsibilities:</h3>
                                            <p>In line with the vision and Mission statement of the University, the Vice- Chancellor who is the Chief Executive officer of the University and responsible for the day– to- day running of the Institution is carefully selected to discharge the following summarized responsibilities: </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="more-info-items two-items">
                                                <div className="media more-info-item">
                                                    <div className="media-body">
                                                        <p>
                                                            <ul style={{ listStyle: "initial" }}>
                                                                <li>Serving on council committees to provide valuable input in policy</li>
                                                                <li>Provides leadership to the senate for the overall development and management of the academic affairs of the university.</li>
                                                                <li>Managing students' academic affairs, students' discipline and students' issues generally.</li>
                                                                <li>Coordination of academic programs and research budgets and estimates to provide for the overall financial needs of the institution.</li>
                                                                <li>Attend to requirements of external regulatory bodies in representing approved programs and introduction of new ones.</li>
                                                                <li>Maintaining relationships with other development of academic institutions, organizations, and the broader community.</li>
                                                                <li>Attend to critical events of the university such as graduations, Matriculations, Orientations for new students, and other Public events organized by the University</li>
                                                                <li>Ensuring university standards are maintained and that academic freedom is protected.</li>
                                                                <li>The Vice-chancellor Chairs the Senate and presides over meetings of congregation and in the absence of the Chancellor, the Vice-Chancellor presides over convocation for the conferment of degrees, and other academic titles and distinctions of the university.</li>

                                                            </ul>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="media more-info-item">
                                                    <p>
                                                        <ul style={{ listStyle: "initial" }}>
                                                            <li>Maintaining relationships with other development of academic institutions, organizations, and the broader community.</li>
                                                            <li>providing leadership and direction to the university through policy formulation, development and implementation to ensure accountability and responsibility for efficient and effective compliance with the university law, statutes, rules, regulations, policies and procedures</li>
                                                            <li>The Vice-Chancellor is ensures that the provisions of the law, the statutes and regulations of the land and the university are observed by the university.</li>
                                                            <li>Allocating resources fairly</li>
                                                            <li>Secures grants within the university.</li>
                                                            <li>Supervising staff and performance issues.</li>
                                                            <li>Handling a range of administrative duties, including preparing reports to the Council and Board of Trustees.</li>
                                                            <li>Building networks with other academics and institutions such as the Committee of Vice Chancellors of Nigerian Universities.</li>
                                                            <li>undertake any other duties that may be assigned or referred to him by the Council or Board of Trustees
                                                            </li>


                                                        </ul>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>;
    }
}

export default ViceChancelloroffice;
